<template>
    <div>
        <div v-if="fetchingInstances === false">
            <div class="d-flex flex-wrap align-center">
                <v-checkbox class="mx-4" v-model="showSpaceAdmins">
                    <template v-slot:label>
                        <div>
                            <v-icon class="mr-1" small color="primary">security</v-icon>
                            <span class="primary--text caption text-uppercase">administrators</span>
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox class="mx-4" v-model="showInstanceEditors">
                    <template v-slot:label>
                        <div>
                            <v-icon class="mr-1" small color="primary">edit</v-icon>
                            <span class="primary--text caption text-uppercase">editors</span>
                        </div>
                    </template></v-checkbox
                >
                <v-checkbox class="mx-4" v-model="showInstanceViewers">
                    <template v-slot:label>
                        <div>
                            <v-icon class="mr-1" small color="primary">visibility</v-icon>
                            <span class="primary--text caption text-uppercase">viewers</span>
                        </div>
                    </template>
                </v-checkbox>
                <v-spacer />
                <v-menu v-model="instanceMenu" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-btn outlined v-on="on" text color="primary" class="flex-shrink-1 mr-1">
                            <div class="d-flex align-center justify-space-between">
                                <v-icon small>search</v-icon>
                                <div class="ml-1 primary--text font-weight-bold caption text-truncate flex-shrink-1">{{ selectedInstance }}</div>
                            </div>
                        </v-btn>
                    </template>
                    <v-list style="max-height:500px" class="overflow-y-auto" nav dense>
                        <v-list-item>
                            <v-text-field
                                v-model="instanceSearch"
                                autofocus
                                class="ma-0 pa-0"
                                append-icon="search"
                                label="Filter"
                                dense
                                single-line
                            ></v-text-field>
                        </v-list-item>
                        <v-list-item v-for="item in instanceNames" :key="item" @click="selectedInstance = item">
                            <v-list-item-title>
                                {{ item }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <AddInstanceDialog :outlinedButton="true" @inviteUsers="inviteToInstance($event.instanceName)"> </AddInstanceDialog>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="ml-1" v-on="on" @click="$store.dispatch('spaceStore/fetchSpaceInstances', $route.params.sid)" text icon
                            ><v-icon>refresh</v-icon></v-btn
                        >
                    </template>
                    <span>refresh</span>
                </v-tooltip>
            </div>
            <v-divider class="mb-5"></v-divider>
            <v-data-iterator :items="sortedFilteredInstances">
                <template v-slot:default="props">
                    <div v-for="(item, index) in props.items" :key="item.iid">
                        <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                        <v-list-item two-line>
                            <v-row align="center">
                                <v-col cols="1">
                                    <v-list-item-avatar>
                                        <v-icon v-if="item.long_id === instanceFixedNames.MASTER_INSTANCE" x-large>star</v-icon>
                                        <v-icon v-else-if="item.long_id === instanceFixedNames.DISTRIBUTED_INSTANCE" x-large>share</v-icon>
                                        <v-icon v-else x-large>group</v-icon>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="5">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.long_id }} <v-chip x-small v-if="item.is_active === true" color="success">Active</v-chip>
                                            <v-chip x-small v-else-if="item.is_active === false" color="error">Inactive</v-chip>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="caption mr-3 font-weight-bold">{{ item.description }}</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item-content>
                                        <v-list-item-subtitle style="width:30%">
                                            <ul style="list-style-type: none;">
                                                <div v-if="spaceAdmins.length && showSpaceAdmins === true">
                                                    <ComponentWithStyledBorder v-for="admin in spaceAdmins" :key="admin.ucid">
                                                        <li>
                                                            <div class="d-flex align-center">
                                                                <v-icon class="mr-1 ml-1">security</v-icon>
                                                                {{ admin.full_name }}
                                                            </div>
                                                        </li>
                                                    </ComponentWithStyledBorder>
                                                </div>
                                                <div v-if="instancesWithNonAdminRole.includes(item.long_id)">
                                                    <ComponentWithStyledBorder>
                                                        <li v-for="(role, index) in filterInstanceRolesPerInstance(item.long_id)" :key="index">
                                                            <div v-if="role.Instance === item.long_id" class="d-flex  align-center">
                                                                <v-icon v-if="role.role_name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1"
                                                                    >visibility</v-icon
                                                                >
                                                                <v-icon v-else-if="role.role_name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1">edit</v-icon>
                                                                {{ role.full_name }}
                                                            </div>
                                                        </li>
                                                    </ComponentWithStyledBorder>
                                                </div>
                                                <div>
                                                    <v-hover v-slot:default="{ hover }">
                                                        <li
                                                            @click="inviteToInstance(item.long_id)"
                                                            :style="hover ? 'background-color: rgb(96, 96, 96, 0.1);cursor: pointer;' : ''"
                                                        >
                                                            <div style="height:24px" class="d-flex align-center">
                                                                <v-icon small color="secondary">add</v-icon><span class="secondary--text">Invite users</span>
                                                            </div>
                                                        </li>
                                                    </v-hover>
                                                </div>
                                            </ul>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                            </v-row>
                            <v-list-item-action>
                                <InstanceDeleteDialog
                                    :instanceId="parseInt(item.iid, 10)"
                                    :instanceName="item.long_id"
                                    :instanceDescription="item.description"
                                    :masterInstanceId="masterInstanceData.iid.toString()"
                                    :masterDevelopmentSnapshotId="masterInstanceData.snid.toString()"
                                ></InstanceDeleteDialog>
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                    <div>
                        <v-list-item @click="$emit('goToInvite', { value: true })" two-line>
                            <v-row align="center">
                                <v-col cols="1">
                                    <v-list-item-avatar>
                                        <v-icon x-large>add</v-icon>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item-content>
                                        <v-list-item-title>Invite</v-list-item-title>
                                    </v-list-item-content>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </div>
                </template>
            </v-data-iterator>
        </div>
        <div v-else-if="fetchingInstances === true">
            <div
                v-for="item in [
                    { id: 'c1', opacity: 1 },
                    { id: 'c2', opacity: 0.75 },
                    { id: 'c3', opacity: 0.5 }
                ]"
                :key="item.id"
                class="my-6"
            >
                <div :style="{ opacity: item.opacity }">
                    <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { sortArray, isMasterInstance, isCurrentState } from '@/utils'
const InstanceDeleteDialog = () => import('@/modules/instance/components/TheInstanceDeleteDialog')
const AddInstanceDialog = () => import('@/modules/space/components/TheSpaceAddInstanceDialog')
const ComponentWithStyledBorder = () => import('./SpaceManagementComponentWithStyledBorder')

export default {
    mixins: [enumsData],
    components: { InstanceDeleteDialog, AddInstanceDialog, ComponentWithStyledBorder },
    data() {
        return {
            instanceSearch: '',
            expand: false,
            showInstanceViewers: true,
            showInstanceEditors: true,
            showSpaceAdmins: true,
            selectedInstance: 'All Instances',
            instanceMenu: false
        }
    },
    methods: {
        deleteInstance: function(instanceName, description, iid) {
            this.$router.push({
                name: 'instance-delete',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid,
                    target_iid: iid
                }
            })
        },
        inviteToInstance: function(instanceName) {
            this.$emit('instanceData', { data: { instanceName: instanceName } })
        },
        filterInstanceRolesPerInstance: function(instanceName) {
            return this.instanceRoles.filter(instance => instance.Instance === instanceName)
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceInstances', 'spaceUsers', 'fetchingInstances', 'fullInstanceSnapshotData']),
        instanceRoles() {
            return this.spaceUsers
                .filter(user => user.instance_viewer_roles.length || user.instance_editor_roles.length)
                .map(user => {
                    const userViewerData = user.instance_viewer_roles.map(instance =>
                        Object.assign(instance, { ucid: user.ucid, uid: user.uid, full_name: user.full_name, email: user.email })
                    )
                    const userEditorData = user.instance_editor_roles.map(instance =>
                        Object.assign(instance, { ucid: user.ucid, uid: user.uid, full_name: user.full_name, email: user.email })
                    )
                    if (this.$data.showInstanceViewers === true && this.$data.showInstanceEditors === true) {
                        return userViewerData.concat(userEditorData)
                    } else if (this.$data.showInstanceViewers === true) {
                        return userViewerData
                    } else if (this.$data.showInstanceEditors === true) {
                        return userEditorData
                    } else {
                        return []
                    }
                })
                .reduce((a, b) => [...a, ...b], [])
        },
        masterInstanceData() {
            if (this.fullInstanceSnapshotData) {
                const master = this.fullInstanceSnapshotData.find(instance => isMasterInstance(instance.instance_name) === true)
                const masterCurrentState = master.data.find(snapshot => isCurrentState(snapshot.short_id) === true)
                return masterCurrentState
            }
            return null
        },
        instancesWithNonAdminRole() {
            return this.instanceRoles.map(instance => instance.Instance)
        },
        spaceAdmins() {
            return this.spaceUsers.filter(user => user.space_role === this.roleTypes.SPACE_ADMIN)
        },
        sortedFilteredInstances() {
            if (this.$data.selectedInstance === 'All Instances') {
                const masterAndDistributedNames = [this.instanceFixedNames.MASTER_INSTANCE, this.instanceFixedNames.DISTRIBUTED_INSTANCE]
                const masterInstance = this.spaceInstances.filter(instance => instance.long_id === this.instanceFixedNames.MASTER_INSTANCE)
                const distributedInstance = this.spaceInstances.filter(instance => instance.long_id === this.instanceFixedNames.DISTRIBUTED_INSTANCE)
                const nonMasterAndDistributed = this.spaceInstances.filter(instance => !masterAndDistributedNames.includes(instance.long_id))
                const sortedNonMasterAndDistributedArray = sortArray(nonMasterAndDistributed, 'long_id', 'ascending', true)
                return masterInstance.concat(sortedNonMasterAndDistributedArray).concat(distributedInstance)
            } else {
                return this.spaceInstances.filter(instance => instance.long_id === this.$data.selectedInstance)
            }
        },
        instanceNames() {
            if (this.spaceInstances && this.$data.instanceSearch === '') {
                return ['All Instances'].concat(this.spaceInstances.map(instance => instance.long_id))
            } else if (this.spaceInstances) {
                return this.spaceInstances
                    .filter(instance => instance.long_id.toLowerCase().includes(this.$data.instanceSearch.toLowerCase()))
                    .map(instance => instance.long_id)
            }
            return []
        }
    },
    watch: {
        instanceMenu: function(nextVal) {
            if (nextVal && this.$data.instanceSearch.length) {
                this.$data.instanceSearch = ''
            }
        },
        instanceNames: function(nextVal) {
            if (nextVal && nextVal.length && this.$data.selectedInstance !== 'All Instances' && nextVal.includes(this.$data.selectedInstance) === false) {
                this.$data.selectedInstance = 'All Instances'
            }
        }
    }
}
</script>
