var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.fetchingInstances === false
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-center" },
              [
                _c("v-checkbox", {
                  staticClass: "mx-4",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "primary" }
                                  },
                                  [_vm._v("security")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "primary--text caption text-uppercase"
                                  },
                                  [_vm._v("administrators")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2579418372
                  ),
                  model: {
                    value: _vm.showSpaceAdmins,
                    callback: function($$v) {
                      _vm.showSpaceAdmins = $$v
                    },
                    expression: "showSpaceAdmins"
                  }
                }),
                _c("v-checkbox", {
                  staticClass: "mx-4",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "primary" }
                                  },
                                  [_vm._v("edit")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "primary--text caption text-uppercase"
                                  },
                                  [_vm._v("editors")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1984619348
                  ),
                  model: {
                    value: _vm.showInstanceEditors,
                    callback: function($$v) {
                      _vm.showInstanceEditors = $$v
                    },
                    expression: "showInstanceEditors"
                  }
                }),
                _c("v-checkbox", {
                  staticClass: "mx-4",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "primary" }
                                  },
                                  [_vm._v("visibility")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "primary--text caption text-uppercase"
                                  },
                                  [_vm._v("viewers")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1834914581
                  ),
                  model: {
                    value: _vm.showInstanceViewers,
                    callback: function($$v) {
                      _vm.showInstanceViewers = $$v
                    },
                    expression: "showInstanceViewers"
                  }
                }),
                _c("v-spacer"),
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "", "close-on-content-click": false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "flex-shrink-1 mr-1",
                                    attrs: {
                                      outlined: "",
                                      text: "",
                                      color: "primary"
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-space-between"
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("search")
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ml-1 primary--text font-weight-bold caption text-truncate flex-shrink-1"
                                        },
                                        [_vm._v(_vm._s(_vm.selectedInstance))]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1384618529
                    ),
                    model: {
                      value: _vm.instanceMenu,
                      callback: function($$v) {
                        _vm.instanceMenu = $$v
                      },
                      expression: "instanceMenu"
                    }
                  },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass: "overflow-y-auto",
                        staticStyle: { "max-height": "500px" },
                        attrs: { nav: "", dense: "" }
                      },
                      [
                        _c(
                          "v-list-item",
                          [
                            _c("v-text-field", {
                              staticClass: "ma-0 pa-0",
                              attrs: {
                                autofocus: "",
                                "append-icon": "search",
                                label: "Filter",
                                dense: "",
                                "single-line": ""
                              },
                              model: {
                                value: _vm.instanceSearch,
                                callback: function($$v) {
                                  _vm.instanceSearch = $$v
                                },
                                expression: "instanceSearch"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._l(_vm.instanceNames, function(item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item,
                              on: {
                                click: function($event) {
                                  _vm.selectedInstance = item
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c("AddInstanceDialog", {
                  attrs: { outlinedButton: true },
                  on: {
                    inviteUsers: function($event) {
                      return _vm.inviteToInstance($event.instanceName)
                    }
                  }
                }),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-1",
                                    attrs: { text: "", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$store.dispatch(
                                          "spaceStore/fetchSpaceInstances",
                                          _vm.$route.params.sid
                                        )
                                      }
                                    }
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1215902818
                    )
                  },
                  [_c("span", [_vm._v("refresh")])]
                )
              ],
              1
            ),
            _c("v-divider", { staticClass: "mb-5" }),
            _c("v-data-iterator", {
              attrs: { items: _vm.sortedFilteredInstances },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._l(props.items, function(item, index) {
                          return _c(
                            "div",
                            { key: item.iid },
                            [
                              index > 0
                                ? _c("v-divider", {
                                    staticClass: "mb-2",
                                    staticStyle: {
                                      opacity: "0.25",
                                      "border-width": "thin"
                                    },
                                    attrs: { inset: "" }
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { align: "center" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            [
                                              item.long_id ===
                                              _vm.instanceFixedNames
                                                .MASTER_INSTANCE
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-large": "" }
                                                    },
                                                    [_vm._v("star")]
                                                  )
                                                : item.long_id ===
                                                  _vm.instanceFixedNames
                                                    .DISTRIBUTED_INSTANCE
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-large": "" }
                                                    },
                                                    [_vm._v("share")]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-large": "" }
                                                    },
                                                    [_vm._v("group")]
                                                  )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.long_id) +
                                                      " "
                                                  ),
                                                  item.is_active === true
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "success"
                                                          }
                                                        },
                                                        [_vm._v("Active")]
                                                      )
                                                    : item.is_active === false
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "error"
                                                          }
                                                        },
                                                        [_vm._v("Inactive")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-subtitle", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption mr-3 font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.description)
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticStyle: { width: "30%" }
                                                },
                                                [
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticStyle: {
                                                        "list-style-type":
                                                          "none"
                                                      }
                                                    },
                                                    [
                                                      _vm.spaceAdmins.length &&
                                                      _vm.showSpaceAdmins ===
                                                        true
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              _vm.spaceAdmins,
                                                              function(admin) {
                                                                return _c(
                                                                  "ComponentWithStyledBorder",
                                                                  {
                                                                    key:
                                                                      admin.ucid
                                                                  },
                                                                  [
                                                                    _c("li", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex align-center"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-1 ml-1"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "security"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                admin.full_name
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.instancesWithNonAdminRole.includes(
                                                        item.long_id
                                                      )
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "ComponentWithStyledBorder",
                                                                _vm._l(
                                                                  _vm.filterInstanceRolesPerInstance(
                                                                    item.long_id
                                                                  ),
                                                                  function(
                                                                    role,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: index
                                                                      },
                                                                      [
                                                                        role.Instance ===
                                                                        item.long_id
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex  align-center"
                                                                              },
                                                                              [
                                                                                role.role_name ===
                                                                                _vm
                                                                                  .roleTypes
                                                                                  .INSTANCE_VIEWER
                                                                                  ? _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1 ml-1"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "visibility"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : role.role_name ===
                                                                                    _vm
                                                                                      .roleTypes
                                                                                      .INSTANCE_EDITOR
                                                                                  ? _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1 ml-1"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "edit"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      role.full_name
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("v-hover", {
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var hover =
                                                                      ref.hover
                                                                    return [
                                                                      _c(
                                                                        "li",
                                                                        {
                                                                          style: hover
                                                                            ? "background-color: rgb(96, 96, 96, 0.1);cursor: pointer;"
                                                                            : "",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.inviteToInstance(
                                                                                item.long_id
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-center",
                                                                              staticStyle: {
                                                                                height:
                                                                                  "24px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    color:
                                                                                      "secondary"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "add"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "secondary--text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Invite users"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("InstanceDeleteDialog", {
                                        attrs: {
                                          instanceId: parseInt(item.iid, 10),
                                          instanceName: item.long_id,
                                          instanceDescription: item.description,
                                          masterInstanceId: _vm.masterInstanceData.iid.toString(),
                                          masterDevelopmentSnapshotId: _vm.masterInstanceData.snid.toString()
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "v-list-item",
                              {
                                attrs: { "two-line": "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("goToInvite", {
                                      value: true
                                    })
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "1" } },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-large": "" } },
                                              [_vm._v("add")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v("Invite")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1184431833
              )
            })
          ],
          1
        )
      : _vm.fetchingInstances === true
      ? _c(
          "div",
          _vm._l(
            [
              { id: "c1", opacity: 1 },
              { id: "c2", opacity: 0.75 },
              { id: "c3", opacity: 0.5 }
            ],
            function(item) {
              return _c("div", { key: item.id, staticClass: "my-6" }, [
                _c(
                  "div",
                  { style: { opacity: item.opacity } },
                  [
                    _c("v-skeleton-loader", {
                      staticClass: "mx-auto",
                      attrs: { type: "list-item-avatar-two-line" }
                    })
                  ],
                  1
                )
              ])
            }
          ),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }